import {useState} from 'react'
import {Link, useLocation, useRoutes} from "react-router-dom";

const navbarList = [
    {
        id: 0,
        name: 'Home',
        href: "/#"
    },
    {
        id: 1,
        name: 'About',
        href: "/#about"
    },
    {
        id: 2,
        name: 'Character',
        href: "/#character"
    }
]

const Header = () => {
    const activeState = (()=>{
        return [...navbarList, {id: 4, name: 'campaign', href: '/campaign'}].find((item)  => window.location.href.includes(item.href))?.id ?? 0
    })();
    const [menuClass, setMenuClass] = useState("");
    const [menuItemClass, setMenuItemClass] = useState(activeState);

    const toggleMenu = () => !!menuClass ? setMenuClass('') : setMenuClass('active')

    const clickNavBar = (id) => setMenuItemClass(id)

    return <>
        <header id="header" className="header font-2p">
            <nav className="navbar container">
                <a href="#" className="brand hover">
                    <img src="/assets/images/logo.png" alt="'logo"/>
                </a>
                <div className={`menu ${menuClass}`} id="menu">
                    <ul className="menu-inner">
                        {navbarList.map((item, index) => (
                            <li className={`menu-item ${item.id === menuItemClass ? 'active' : ''}`}
                                onClick={() => clickNavBar(item.id)}>
                                <a href={item.href} className="menu-link">{item.name}</a>
                                <img src="/assets/icons/tickv.png" alt="icon tick"/>
                            </li>
                        ))}
                        <li className="menu-item">
                            <a href="https://linktr.ee/dropshit" target="_blank" className="menu-link">Contact</a>
                        </li>
                        {/*<li className={`menu-item ${menuItemClass === 4 ? 'active' : ''}`}>*/}
                        {/*    <Link to='/campaign' className="menu-link">Campaign</Link>*/}
                        {/*</li>*/}
                    </ul>
                </div>
                <a href="https://t.me/drop_shit_game_bot" target="_blank"
                   className="menu-block d-md-block d-none menu-play animate__animated animate__heartBeat animate__infinite animate__slower">
                    <img src="/assets/images/box-yellow-header.png" alt="yellow header"/>
                    <p>PLAY NOW</p>
                </a>
                <div className='d-md-none d-block' id="btn-menu" onClick={toggleMenu}>
                    <img className="close" src="/assets/icons/close.png" alt=""/>
                    <img className="open" src="/assets/icons/menu.png" alt=""/>
                </div>
            </nav>
        </header>
    </>
}

export default Header;