import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./screens/Home";
import Campaign from "./screens/Campaingn";

function App(){
    const listRouterPaths = [
        {
            path: "/",
            element: <Home />,
        },
        // {
        //     path: "/campaign",
        //     element: <Campaign />,
        // },
    ];

    const createRouter = createBrowserRouter(listRouterPaths);

    return (
        <div id="app">
            <RouterProvider router={createRouter} />
        </div>
    );
}

export default  App;