import {Link} from "react-router-dom";

const Footer = () => {
    return <>
    <footer id="footer" className="footer">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                    <img className="logo mb-5" src="/assets/images/logo.png" alt="logo"/>
                </div>
                <div className="col-12 col-md-8 col-lg-9 d-flex justify-content-between">
                    <div className="menu-nav d-flex flex-column">
                        <a href="/#" className="nav-item mb-3">Home</a>
                        <a href="/#about" className="nav-item mb-3">About Game</a>
                        <a href="/#character" className="nav-item mb-3">Character Game</a>
                        <a href="https://linktr.ee/dropshit" target="_blank" className="nav-item mb-3">Contact</a>
                        {/*<Link to='/campaign' className="nav-item mb-3">Campaign</Link>*/}
                    </div>

                    <div className="">
                        <h5 className="text fs-md-11px">Experience the game now</h5>
                        <div className="d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18"
                                 fill="none">
                                <rect width="3.93678" height="1.96839" transform="matrix(-1 0 0 1 15.4822 0.388184)"
                                      fill="white"
                                      fill-opacity="0.4"/>
                                <rect width="3.93678" height="1.96839" transform="matrix(-1 0 0 1 15.4822 15.6436)"
                                      fill="white"
                                      fill-opacity="0.4"/>
                                <rect width="3.93678" height="1.96839" transform="matrix(-1 0 0 1 17.4504 1.86475)"
                                      fill="white"
                                      fill-opacity="0.4"/>
                                <rect width="3.93678" height="1.96839" transform="matrix(-1 0 0 1 17.4504 13.6748)"
                                      fill="white"
                                      fill-opacity="0.4"/>
                                <rect width="3.93678" height="1.96839" transform="matrix(-1 0 0 1 19.4188 3.83301)"
                                      fill="white"
                                      fill-opacity="0.4"/>
                                <rect width="3.93678" height="1.96839" transform="matrix(-1 0 0 1 19.4188 11.7065)"
                                      fill="white"
                                      fill-opacity="0.4"/>
                                <rect width="20.6681" height="1.96839" transform="matrix(-1 0 0 1 21.3872 5.80127)"
                                      fill="white"
                                      fill-opacity="0.4"/>
                                <rect width="20.6681" height="1.96839" transform="matrix(-1 0 0 1 21.3872 9.73828)"
                                      fill="white"
                                      fill-opacity="0.4"/>
                                <rect width="22.1444" height="1.96839" transform="matrix(-1 0 0 1 22.8635 7.76953)"
                                      fill="white"
                                      fill-opacity="0.4"/>
                            </svg>

                            <a href="https://t.me/drop_shit_game_bot" target="_blank" className="play-box ml-2">
                                <img src="/assets/images/box-yellow.png" alt="yellow box" className="box-yellow"/>
                                <div
                                    className="text width-mobile-100 text-wrap fs-md-11px animate__flash animate__animated animate__infinite">
                                    Play Now
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="my-5 w-100" style={{
                borderTop: '1px solid rgba(255, 255, 255, 0.1)'
            }}/>
            <div
                className="d-flex justify-content-md-between align-items-md-center align-items-start flex-column flex-md-row">
                <h5 className="copy-right fs-md-11px">Copyright 2024 by dropsh.it</h5>
                <div className="flex-first flex-md-last mb-md-0 mb-5">
                    <a href="https://docs.dropsh.it/" target="_blank" className="mr-3">
                        <img src="/assets/icons/white-paper.png" alt="x" className=""/>
                    </a>
                    <a href="https://twitter.com/Drop_shit_game" target="_blank" className="mr-3">
                        <img src="/assets/icons/x.png" alt="x" className=""/>
                    </a>
                    <a href="https://t.me/dropshit_official_chat" target="_blank">
                        <img src="/assets/icons/telegram.png" alt="telegram" className=""/>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</>
}

export default Footer;