import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import {useRef, useState} from 'react';


const AboutGame = () => {
    const [isVideo1Playing, setIsVideo1Playing] = useState(false);
    const [isVideo2Playing, setIsVideo2Playing] = useState(false);
    const video1 = useRef(null);
    const video2 = useRef(null);

    const toggleStateVideo = (videoId) => {
        if(videoId === 1){
            if(isVideo1Playing){
                video1.current.play();
            }else {
                video1.current.pause();
            }
            setIsVideo1Playing(!isVideo1Playing)
        }
        else if(videoId === 2){
            if(isVideo2Playing){
                video2.current.play();
            }else {
                video2.current.pause();
            }
            setIsVideo2Playing(!isVideo2Playing)
        }
    }
    return <div className="about" id="about">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-shadow title fs-md-20px pt-1 mb-5">
                                About<br/>Game
                            </h1>
                        </div>
                        <div className="col-2 col-md-12">
                            <img className="my-md-5 pr-3 quotation"
                                 src="/assets/images/double-quotation-marks.png" alt="more"/>
                        </div>
                        <p className="col-10 col-md-12 text fs-md-11px">
                            Dodge falling p00ps to score high and level up in this quirky
                            hyper-casual game! Collect coins and personalize your
                            experience with unique NFT skins, adding flair to your
                            escapades. Join now and transform dodging into winning!
                        </p>
                    </div>
                </div>
                <div
                    className="overflow-hidden col-12 col-md-6 pt-3 pt-md-0 hover d-flex flex-column justify-content-between p-0">
                    <div className="carousel">
                        <Swiper
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="carousel__slide__content">
                                    <video id="my-video" className="" preload="auto" width="100%"
                                           poster="/assets/images/video.png" ref={video1} onClick={() => toggleStateVideo(1)}>
                                        <source src="/assets/video/demo_02.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="carousel__slide__content">
                                    <video id="my-video" className="" preload="auto" width="100%"
                                           poster="/assets/images/video-2.png" itemref={video2} ref={video2} onClick={() => toggleStateVideo(2)}>
                                        <source src="/assets/video/demo.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default AboutGame;