import Layout from "../../components/Layout";
import PlayNow from "./components/PlayNow";
import AboutGame from "./components/AboutGame";
import Character from "./components/Character";
import Sponser from "./components/Sponser";

const Home = () => {
    return <Layout>
        <main id="main" className="main">
            <PlayNow />
            <AboutGame />
            <Character />
            <Sponser />
        </main>
    </Layout>

}

export default Home;