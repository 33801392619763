const Sponser = () => {

    return <div className="sponser">
        <div className="container">
            <div className='row d-flex justify-content-between align-items-center '>
                <div className='col-12 col-md-4'>
                    <img src='/assets/images/sponser1.png' alt='DIAMANTE BLOCKCHAIN' />
                </div>
                <div className='col-12 col-md-4 my-4 my-md-0 d-flex justify-content-md-center'>
                    <img src='/assets/images/sponser2.png' alt='TON BLOCKCHAIN'/>
                </div>
                <div className='col-12 col-md-4 d-flex justify-content-md-end'>
                    <img src='/assets/images/sponser3.png' alt='BITLAYER BLOCKCHAIN'/>
                </div>
            </div>
        </div>
    </div>
}

export default Sponser;