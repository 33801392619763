const PlayNow = () => {
   return <div className="play">
        <img src="/assets/images/background.png" className="background" alt="background"/>
        <div className="object-bg d-flex">
            <img src="/assets/images/object-bg.png" className=" " alt="object-bg"/>
            <img src="/assets/images/object-bg.png" className=" " alt="object-bg"/>
        </div>
        <img src="/assets/icons/sht.png" alt="sh!t logo"
             className="sht sht-left animate__animated animate__shakeY animate__infinite animate__slower"/>
        <img src="/assets/icons/sht.png" alt="sh!t logo"
             className="sht sht-right animate__animated animate__shakeY animate__infinite animate__slow"/>
        <img src="/assets/icons/sht.png" alt="sh!t logo"
             className="sht sht-center animate__animated animate__shakeY animate__infinite animate__slower"/>
        <img src="/assets/icons/text-play.png" alt="text logo"
             className="text-play animate__animated animate__fadeInDownBig"/>
        <img src="/assets/images/siba-dog.png" alt="text logo"
             className="character dog animate__animated animate__tada animate__infinite animate__slow"/>
        <img src="/assets/images/pepe-meme.png" alt="text logo"
             className="character pepe animate__animated animate__rubberBand animate__infinite animate__slow"/>
        <a href="https://t.me/drop_shit_game_bot" target="_blank" className="btn-play">
            <img src="/assets/images/box-yellow.png" alt="playnow"
                 className="animate__jackInTheBox animate__animated"/>
            <p className="fs-md-11px animate__flash animate__animated animate__infinite animate__slower">
                Play Now
            </p>
        </a>
    </div>

}

export default PlayNow;