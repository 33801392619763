const characterAssets = [
    {
        src: '/assets/images/siba-box.png',
        name: <div>shiba <br/>the doge</div>,
        color: '#8A57F6'
    },
    {
        src: '/assets/images/meme-box.png',
        name: <div>pepe <br/>the frog</div>,
        color: '#f4cb37'
    },
    {
        src: '/assets/images/wojak-meme.png',
        name: <div>wojak <br/>the guy</div>,
        color: '#8A57F6'
    },
    {
        src: '/assets/images/cat-box.png',
        name: <div>sumdge <br/>the cat</div>,
        color: '#f4cb37'
    }
]


const CharacterComponent = ({srcImage, name, color}) => {
    return (<div className="col-6 col-md-3">
        <div className="row px-2">
            <img className="character-img" src={srcImage}
                 alt="siba box"/>
            <h3 className="character-name mt-4 text-shadown w-100 text-center fs-md-11px" style={{
                color: color
            }}>
                {name}
            </h3>
        </div>
    </div>)
}

const Character = () => {
    return (
        <div className="character" id="character">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-12 d-flex justify-content-between">
                        <h2 className="title text-shadow pl-3 fs-md-20px pr-2">
                            game<br/>characters
                        </h2>
                        <img src="/assets/icons/move-left-top.png" className="move-left-top mt-2"
                             alt="icon move-left-top"/>
                    </div>
                </div>
                <div className="row pl-3">
                    {characterAssets.map((item, index) => <CharacterComponent srcImage={item.src} name={item.name}
                                                                     color={item.color}/>)}
                </div>
            </div>
        </div>
    )
}

export default Character;